import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

//Component Import
import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';
import DownArrow from '../images/assets/rightArrow.svg';
import Squiggle from '../components/squiggle';
import linkStripper from '../utils/link-stripper';
import LazyImage from '../components/lazy-image';

const HeaderThreeContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: relative;
  height: 626px;
  max-height: 1000px;
  overflow: hidden;
  background-color: #212121;
  p {
    margin: 0;
  }
  @media (max-width: 800px) {
    height: 70vh;
    min-height: 450px;
  }
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-family: Montserrat;
  color: ${props => props.color};
  margin-top: 50px;

  width: 90%;
  margin-left: 5%;
  @media (max-width: 800px) {
    margin-bottom: 0px;
  }
`;

const CenterContent = styled.div`
  max-width: 500px;
  position: relative;
  display: flex;
  align-items: center;
`;

const TextContent = styled(motion.div)`
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LeftContent = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  margin-bottom: 150px;
`;

const LeftImage = styled(motion.img)`
  max-height: 550px;
  margin-top: -00px;
  @media (max-width: 1025px) {
    display: none;
  }
`;

const RightImage = styled(motion.img)`
  max-height: 470px;
  @media (max-width: 1225px) {
    width: 240px;
    height: 435px;
  }
  @media (max-width: 1025px) {
    display: none;
  }
`;

const CtaContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  cursor: pointer;
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const CtaContainerInner = styled(motion.a)``;

const ArrowContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  transform: rotate(90deg);
`;

const CtaText = styled(motion.div)`
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #fff;
`;

const SquiggleContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: -10vw;
  mix-blend-mode: hard-light;
  margin-top: 140px;
  @media (max-width: 800px) {
    left: -30vw;
  }
  @media (max-width: 600px) {
    left: -50vw;
    top: 35%;
  }
`;

const LeftImageContainer = styled(motion.div)`
  height: 565px;
  width: 365px;
  margin-top: 60px;
  @media (max-width: 1225px) {
    height: 400px;
    width: 225px;
  }
  @media (max-width: 1025px) {
    display: none;
  }
`;

const RightImageContainer = styled(motion.div)`
  width: 340px;
  height: 475px;
  @media (max-width: 1225px) {
    width: 240px;
    height: 435px;
  }
  @media (max-width: 1025px) {
    display: none;
  }
`;

const TitleContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
`;

const TextContentInner = styled.div``;

const HeaderThree = ({
  header_text,
  subtitle_text,
  color_theme,
  background_color,
  header_text_color,
  subtitle_text_color,
  right_image,
  left_image,
  cta_text,
  cta_link,
  cta_text_color,
  nonLazyMode,
  cta_link_target,
  customFontSize,
  props,
}) => {
  const [active, setActive] = React.useState(false);
  return (
    <HeaderThreeContainer backgroundColor={background_color}>
      <LeftContent>
        {nonLazyMode ? (
          <LeftImage
            src={left_image}
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: -50 }}
            transition={{ duration: 0.5 }}
          />
        ) : (
          <LeftImageContainer
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: -50 }}
            transition={{ duration: 0.5 }}
          >
            {left_image && <LazyImage {...left_image} />}
          </LeftImageContainer>
        )}
      </LeftContent>
      <CenterContent>
        <TextContent
          animate={{ opacity: 1, translateY: 0 }}
          initial={{ opacity: 0, translateY: -30 }}
          transition={{ duration: 0.4 }}
        >
          <TextContentInner>
            <TitleContainer>
              <TitleUnderlined
                underlineColor={color_theme}
                textColor={header_text_color}
                fontSize={(customFontSize && customFontSize) || '70px'}
                lineHeight={'80px'}
                mobileFontSize={'40px'}
                mobileLineHeight={'40px'}
                titleText={'<span>' + header_text.text + '</span>'}
              />
            </TitleContainer>
            <Subtitle
              dangerouslySetInnerHTML={convertHtml(subtitle_text.text)}
              color="#FFF"
            />
          </TextContentInner>
        </TextContent>
        <CtaContainer
          animate={{ opacity: 1, translateY: 0 }}
          initial={{ opacity: 0, translateY: -50 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <CtaContainerInner
            onHoverStart={() => setActive(!active)}
            onHoverEnd={() => setActive(!active)}
            href={linkStripper(cta_link.url)}
            target={cta_link.target}
          >
            <CtaText
              animate={active ? { color: color_theme } : { color: '#FFF' }}
            >
              {cta_text.text}
            </CtaText>

            <ArrowContainer
              animate={
                active
                  ? { translateY: '5px', rotate: '90deg' }
                  : { translateY: '0px', rotate: '90deg' }
              }
            >
              <DownArrow fill={color_theme} />
            </ArrowContainer>
          </CtaContainerInner>
        </CtaContainer>
      </CenterContent>
      <RightContent>
        {nonLazyMode ? (
          <RightImage
            src={right_image}
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: 50 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          />
        ) : (
          <RightImageContainer
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: 50 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            {right_image && <LazyImage {...right_image} />}
          </RightImageContainer>
        )}
      </RightContent>
    </HeaderThreeContainer>
  );
};

export default HeaderThree;
